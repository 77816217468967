export const DEFAULT_COLORS = {
  PRIMARY: "#7F5CEC",
  PRIMARY_TEXT: "#222635",
  SECONDARY: "#FF556C",
  WHITE: "#FFF",
  BACKGROUND: "#222635",

  CARD_SHADOW: "0px 1px 4px rgba(0, 0, 0, 0.07)",
  FORM_COLOR: "#FAFAFA",
  BORDER: "#E5E5E5",
  ICONS: "#8D8D8D",

  //STATES
  PENDING: "#EDAB37",
  DECLINED: "#E74C3C",
  SUCCESS: "#2CCC71",
};
