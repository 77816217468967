import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import React from "react";
import { useParams } from "react-router-dom";

const AdvancedRealChart = () => {
  const { symbol } = useParams<{ symbol: string }>();
  return (
    <AdvancedRealTimeChart
      theme="light"
      symbol={symbol}
      height={500}
      width="100%"
      hide_side_toolbar
      allow_symbol_change={false}
      locale="en"
      show_popup_button={false}
      disabled_features={[
        "timezone_menu",
        "source_selection_markers",
        "header_compare",
        "context_menus",
        "control_bar",
        "header_widget",
        "main_series_scale_menu",
      ]}
    ></AdvancedRealTimeChart>
  );
};

export default AdvancedRealChart;
