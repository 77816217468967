import axios from "axios";
import { createChart, ColorType, UTCTimestamp } from "lightweight-charts";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { AreaChartOutlined, BarChartOutlined } from "@ant-design/icons";
import { colorsMap } from "../Home/data";
import { useSocketChannel } from "./useSocketChannel";

export const AreaChart = (props: any) => {
  const { symbol, category } = useParams<{
    symbol: string;
    category: string | undefined;
  }>();
  const {
    data,
    colors: {
      backgroundColor,
      lineColor,
      textColor,
      areaTopColor,
      areaBottomColor,
    },
  } = props;
  const socketData = useSocketChannel(
    { type: "instrument", value: symbol },
    "Area",
    new Date(data?.[data?.length - 1]?.time * 1000),
    category === "1" && props?.interval === "1m"
  );
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!chartContainerRef.current) {
      return;
    }

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: "transparent" },
        textColor: "gray",
      },
      // width: chartContainerRef.current?.clientWidth,
      height: 300,
      timeScale: {
        timeVisible: true,
        secondsVisible: category === "1" ? true : false,
        borderVisible: false,
      },
      grid: {
        vertLines: {
          color: "transparent",
        },
        horzLines: {
          color: "transparent",
        },
      },
      leftPriceScale: {
        visible: false,
        borderVisible: false,
      },
      rightPriceScale: {
        visible: true,
        borderVisible: false,
      },
    });

    const newSeries = chart.addAreaSeries({
      lineColor,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
      priceFormat: {
        type: "price",
        precision: 5,
        minMove: 0.00001,
      },
    });
    newSeries.setData([...data, ...socketData]);
    return () => {
      chart.remove();
    };
  }, [
    data,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
    socketData,
    category,
  ]);
  return <div ref={chartContainerRef} />;
};

export const CandleChart = (props: any) => {
  const { symbol, category } = useParams<{
    symbol: string;
    category: string | undefined;
  }>();
  const {
    data,
    colors: { backgroundColor, textColor },
  } = props;
  const socketData = useSocketChannel(
    { type: "instrument", value: symbol },
    "Candle",
    new Date(data?.[data?.length - 1]?.time * 1000),
    category === "1" && props?.interval === "1m"
  );
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!chartContainerRef.current) {
      return;
    }

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: "transparent" },
        textColor: "gray",
      },
      height: 300,
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        borderVisible: false,
      },
      grid: {
        vertLines: {
          color: "transparent",
        },
        horzLines: {
          color: "transparent",
        },
      },
      leftPriceScale: {
        visible: false,
        borderVisible: false,
        entireTextOnly: true,
      },
      rightPriceScale: {
        visible: true,
        borderVisible: false,
        entireTextOnly: true,
      },
    });
    chart.timeScale().applyOptions({
      barSpacing: 15,
      rightOffset: 0,
      fixLeftEdge: true,
      fixRightEdge: true,
    });
    const newSeries = chart.addCandlestickSeries({
      priceFormat: {
        type: "price",
        precision: 5,
        minMove: 0.00001,
      },
    });
    newSeries.setData([...data, ...socketData]);
    return () => {
      chart.remove();
    };
  }, [data, backgroundColor, textColor, socketData, category]);
  return <div ref={chartContainerRef} />;
};

export const ChartComponent = (props: any) => {
  const { data, tab, setTab, interval, setInterval, intervals, colors } = props;

  return (
    <>
      <div className="tabs-wrapper chart-tab">
        <div
          className={`tab ${tab === "Area" ? "active" : ""}`}
          onClick={() => tab !== "Area" && setTab("Area")}
        >
          <AreaChartOutlined />
        </div>
        <div
          className={`tab ${tab === "Candle" ? "active" : ""}`}
          onClick={() => tab !== "Candle" && setTab("Candle")}
        >
          <BarChartOutlined />
        </div>
      </div>
      <div
        className="tabs-wrapper"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        {intervals?.map?.((item: any) => (
          <div
            key={item?.key}
            style={{ fontSize: "12px", padding: "6px 8px" }}
            className={`tab ${interval === item?.label ? "active" : ""}`}
            onClick={() => interval !== item?.label && setInterval(item?.label)}
          >
            {item?.label}
          </div>
        ))}
      </div>
      {tab === "Area" ? (
        <AreaChart data={data} colors={colors} interval={interval} />
      ) : null}
      {tab === "Candle" ? (
        <CandleChart data={data} colors={colors} interval={interval} />
      ) : null}
    </>
  );
};

function removeCharactersAfterDot(inputString: string): string {
    const indexOfDot = inputString.indexOf('.');
    return indexOfDot !== -1 ? inputString.substring(0, indexOfDot) : inputString;
}

const TradingSymbols = (props: any) => {
  const [data, setData] = React.useState<any>([]);
  const { symbol, category } = useParams<{
    symbol: string;
    category: string | undefined;
  }>();
  const [tab, setTab] = React.useState<string>("Candle");
  const [interval, setInterval] = React.useState<string>("");
  const [intervals, setIntervals] = React.useState<any[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    if (symbol) {
      axios
        .get(`https://app.hisa.co/api/trade/instrument/${removeCharactersAfterDot(symbol)}/chart`, {
          headers: {
            Accept: "application/vnd.hisa.v4+json",
          },
        })
        .then((res) => {
          if (res.data) {
            const stocksData = Array.isArray(res.data) ? res.data : [];
            // Time diff in milliseconds for intervals [1m, 5m, 15m, 30m, 1h, 4h]
            // 1m is only available for category 1

            const mappedData = {
              "1m": resampleData(stocksData, 1),
              "5m": resampleData(stocksData, 5),
              "15m": resampleData(stocksData, 15),
              "30m": resampleData(stocksData, 30),
              "1h": resampleData(stocksData, 60),
              "4h": resampleData(stocksData, 240),
            };
            const intervals = ["5m", "15m", "30m", "1h", "4h"];
            if (category === "1") {
              intervals.unshift("1m");
            }
            setIntervals(intervals.map((item) => ({ key: item, label: item })));
            const activeInterval = category === "1" ? "1m" : "5m";
            setInterval(activeInterval);
            setData(mappedData);
          } else {
            setErrorMessage(res.data?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [symbol, category]);

  if (loading) {
    return (
      <div style={{ marginLeft: 20, fontSize: "14px", marginTop: "40px" }}>
        Loading...
      </div>
    );
  }
  if (errorMessage) {
    return (
      <div style={{ marginLeft: 20, fontSize: "14px", marginTop: "40px" }}>
        {errorMessage}
      </div>
    );
  }
  if (interval === "") {
    return (
      <div style={{ marginLeft: 20, fontSize: "14px", marginTop: "40px" }}>
        No interval available
      </div>
    );
  }
  if (data[interval] && data?.[interval]?.length === 0) {
    return (
      <div style={{ marginLeft: 20, fontSize: "12px", marginTop: "40px" }}>
        No data
      </div>
    );
  }

  let stockData = data[interval] || [];

  return (
    <ChartComponent
      {...props}
      tab={tab}
      setTab={setTab}
      interval={interval}
      setInterval={setInterval}
      data={stockData}
      colors={colorsMap}
      intervals={intervals}
    ></ChartComponent>
  );
};

export default TradingSymbols;

// write a function to resample data with interval and return the resampled data
// the interval is in minutes, for example 1, 5, 15, 30, 60, 240
// For example if interval is 5, then the data should be resampled to 5 minutes interval
// Maximum data points should be 500
// The output will be an array of data point objects - {time, open, high, low, close, value}
// the input is array of data array is in the following format [time, open, high, low, close]

const resampleData = (data: any, interval: number) => {
  // Your code here
  const sampleData: any[] = [];
  const dataPoints = 500;
  let startItem = {
    time: data[0][0],
    open: data[0][1],
    high: data[0][2],
    low: data[0][3],
    close: data[0][4],
    value: data[0][4],
  };
  data.forEach((item: any, index: number) => {
    if (index === 0) {
      return;
    }
    const timeDiff = item[0] - startItem.time;
    if (timeDiff >= interval * 60 && sampleData.length < dataPoints) {
      const newItem = {
        time: item[0],
        open: item[1],
        high: item[2],
        low: item[3],
        close: item[4],
        value: item[4],
      };
      sampleData.push(newItem);
      startItem = newItem;
    } else {
      startItem.high = Math.max(startItem.high, item[2]);
      startItem.low = Math.min(startItem.low, item[3]);
      startItem.close = item[4];
      startItem.value = item[4];
    }
  });
  return sampleData?.map?.((item) => ({
    ...item,
    time: (item.time + 3 * 60 * 60) as UTCTimestamp,
  }));
};
