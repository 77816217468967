import axios from "axios";
import { createChart, ColorType } from "lightweight-charts";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { AreaChartOutlined, BarChartOutlined } from "@ant-design/icons";
import { colorsMap } from "./data";

export const ChartComponent = (props: any) => {
  const {
    data,
    tab,
    setTab,
    interval,
    setInterval,
    intervals,
    colors: {
      backgroundColor,
      lineColor,
      textColor,
      areaTopColor,
      areaBottomColor,
    },
  } = props;
  const chartContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current?.clientWidth });
    };
    if (!chartContainerRef.current) {
      return;
    }

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: "transparent" },
        textColor: "gray",
      },
      width: chartContainerRef.current?.clientWidth,
      height: 300,
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        borderVisible: false,
      },
      grid: {
        vertLines: {
          color: "transparent",
        },
        horzLines: {
          color: "transparent",
        },
      },
      leftPriceScale: {
        visible: true,
        borderVisible: false,
      },
      rightPriceScale: {
        visible: false,
      },
    });

    chart.timeScale().fitContent();
    if (tab === "Area") {
      const newSeries = chart.addAreaSeries({
        lineColor,
        topColor: areaTopColor,
        bottomColor: areaBottomColor,
      });
      newSeries.setData(data);
    } else if (tab === "Candle") {
      const newSeries = chart.addCandlestickSeries();
      newSeries.setData(data);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [
    data,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
    tab,
  ]);

  return (
    <>
      <div className="tabs-wrapper chart-tab">
        <div
          className={`tab ${tab === "Area" ? "active" : ""}`}
          onClick={() => tab !== "Area" && setTab("Area")}
        >
          <AreaChartOutlined />
        </div>
        <div
          className={`tab ${tab === "Candle" ? "active" : ""}`}
          onClick={() => tab !== "Candle" && setTab("Candle")}
        >
          <BarChartOutlined />
        </div>
      </div>
      <div
        className="tabs-wrapper"
        style={{ marginTop: "15px", marginBottom: "10px" }}
      >
        {intervals?.map?.((item: any) => (
          <div
            key={item?.key}
            style={{ fontSize: "12px", padding: "6px 8px" }}
            className={`tab ${interval === item?.label ? "active" : ""}`}
            onClick={() => interval !== item?.label && setInterval(item?.label)}
          >
            {item?.label}
          </div>
        ))}
      </div>
      <div ref={chartContainerRef} />
    </>
  );
};

const KenyanStocks = (props: any) => {
  const [data, setData] = React.useState<any>([]);
  const { symbol } = useParams<{ symbol: string }>();
  const [tab, setTab] = React.useState<string>("Area");
  const [interval, setInterval] = React.useState<string>("");
  const [intervals, setIntervals] = React.useState<any>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    if (symbol) {
      axios
        .get(`https://markets.hisa.co/local/market?code=${symbol}`)
        .then((res) => {
          if (res.data?.success) {
            const stocksData = res.data?.data;
            const tempIntervals: string[] = [];
            Object.keys(stocksData).forEach((key) => {
              if (stocksData[key]?.length > 0) {
                tempIntervals.push(key);
              }
            });
            setIntervals(tempIntervals);
            tempIntervals.length > 0 && setInterval(tempIntervals[0]);
            setData(stocksData);
          } else {
            setErrorMessage(res.data?.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [symbol]);

  if (loading) {
    return <div style={{ marginLeft: 20, fontSize: "14px" }}>Loading...</div>;
  }
  if (errorMessage) {
    return (
      <div style={{ marginLeft: 20, fontSize: "14px" }}>{errorMessage}</div>
    );
  }
  if (interval === "") {
    return (
      <div style={{ marginLeft: 20, fontSize: "14px" }}>
        No interval available
      </div>
    );
  }
  if (data[interval] && data?.[interval]?.length === 0) {
    return <div style={{ marginLeft: 20, fontSize: "12px" }}>No data</div>;
  }

  const stockData = data[interval]?.map((item: any) => {
    return {
      time: item.date,
      open: item.open,
      high: item.high,
      low: item.low,
      close: item.close,
      value: item.close,
    };
  });
  const intervalsMap = intervals.map((item: any) => {
    return {
      label: item,
      key: item,
    };
  });

  return (
    <ChartComponent
      {...props}
      tab={tab}
      setTab={setTab}
      interval={interval}
      setInterval={setInterval}
      data={stockData}
      colors={colorsMap}
      intervals={intervalsMap}
    ></ChartComponent>
  );
};

export default KenyanStocks;
