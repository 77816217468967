import "antd/dist/reset.css";
import { createGlobalStyle } from "styled-components";
import { COLORS } from "../../config/theme";

const GlobalStyle = createGlobalStyle`
    body{
        margin:0;
        padding:0;
        font-family: 'Avenir', sans-serif;
        background: #F8F6FE;
    }
    #tradingview_1b1a5 {
        background: #F8F6FE;
    }
    .ant-form-item {
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        .ant-form-item-label {
            font-weight: 500;
            font-size: 13px;
        }
    }

    .home-logo-container {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .logo-store {
          width: 165px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .google{
            width: 200px;
        }
      }
    input:-webkit-autofill {
        background-color: ${(props: any) =>
          props.theme[COLORS.FORM_COLOR]} !important;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]} !important;
        -webkit-box-shadow: 0 0 0 1000px ${(props: any) =>
          props.theme[COLORS.FORM_COLOR]} inset;
        :focus,
        .ant-input-affix-wrapper > input.ant-input:focus {
            -webkit-box-shadow: 0 0 0 1000px white inset !important;
        }
    }

    .hisa-input {
        background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
        border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        box-sizing: border-box;
        border-radius: 5px;
        height: 45px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};

        input[type='password'] {
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};

            :focus {
                background: white;
            }
        }

        .ant-input-password-icon {
            color: ${(props: any) => props.theme[COLORS.ICONS]};
        }
    }

    .mizi-input:focus {
        background: ${(props: any) => props.theme[COLORS.WHITE]};
        box-shadow: none;
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
        background: ${(props: any) => props.theme[COLORS.WHITE]};
        box-shadow: none;
    }
    .primary-button {
        background: ${(props: any) => props.theme[COLORS.PRIMARY]};
        border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
        box-sizing: border-box;
        border-radius: 5px;
        padding: 5px 10px;
        height: 45px;
        color: ${(props: any) => props.theme[COLORS.WHITE]};
        text-shadow: none;
        font-size: 13px;
        min-width: 70px;
        &:hover {
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
            border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
            color: ${(props: any) => props.theme[COLORS.WHITE]};
        }
        &:focus {
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
            border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
            color: ${(props: any) => props.theme[COLORS.WHITE]};
        }
    }
    .tabs-wrapper {
        display: flex;
        align-items: center;
        margin-left: 5px;
        margin-top: 10px;
        .tab {
            padding: 5px 10px;
            margin-left: 10px;
            color: #131722;
            border-radius: 5px;
        }
        .active {
            background: #3179f52e;
        }
    }
    .chart-tab{
        justify-content: flex-end;
        margin-right: 10px;
    }
`;

export default GlobalStyle;
