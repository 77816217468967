import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import MainRouter from "./router";
import { LIGHT_THEME } from "./config/theme";
import GlobalStyle from "./assets/styles/globalStyle";

function App() {
  return (
    <ThemeProvider theme={LIGHT_THEME}>
      <Router>
        <GlobalStyle />
        <MainRouter />
      </Router>
    </ThemeProvider>
  );
}

export default App;
