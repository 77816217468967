import { SymbolOverview } from "react-ts-tradingview-widgets";
import React from "react";
import { useParams } from "react-router-dom";
import { AreaChartOutlined, BarChartOutlined } from "@ant-design/icons";

const App = () => {
  const { symbol } = useParams<{ symbol: string }>();
  const [tab, setTab] = React.useState("Area");
  return (
    <>
      <div className="tabs-wrapper chart-tab">
        <div
          className={`tab ${tab === "Area" ? "active" : ""}`}
          onClick={() => tab !== "Area" && setTab("Area")}
        >
          <AreaChartOutlined />
        </div>
        <div
          className={`tab ${tab === "Candle" ? "active" : ""}`}
          onClick={() => tab !== "Candle" && setTab("Candle")}
        >
          <BarChartOutlined />
        </div>
      </div>
      <SymbolOverview
        symbols={symbol ? [symbol?.split(":")] : []}
        isTransparent
        scalePosition="left"
        chartOnly
        chartType={tab === "Area" ? "area" : "candlesticks"}
        copyrightStyles={{
          parent: { display: "none" },
          link: { display: "none" },
          span: { display: "none" },
        }}
        width={"100%"}
        height="400px"
        lineColor="#7F5CEC"
      />
    </>
  );
};

export default App;
