import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../containers/Home";
import AdvancedRealChart from "../containers/Home/advance";
import KenyanStocks from "../containers/Home/kenya";
import TradingSymbols from "../containers/Trading";

const MainRouter = () => {
  return (
    <Routes>
      <Route path="/us/:symbol" element={<Home />} />
      <Route path="/kenya/:symbol" element={<KenyanStocks />} />
      <Route path="/advance/:symbol" element={<AdvancedRealChart />} />
      <Route path="/ingot/:symbol" element={<TradingSymbols />} />
      <Route path="/ingot/:symbol/:category" element={<TradingSymbols />} />
    </Routes>
  );
};

export default MainRouter;
