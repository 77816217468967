import {
  LineChartOutlined,
  AreaChartOutlined,
  BarChartOutlined,
} from "@ant-design/icons";

export const colorsMap = {
  backgroundColor: "white",
  lineColor: "#7F5CEC",
  textColor: "black",
  areaTopColor: "#2962FF",
  areaBottomColor: "rgba(127, 92, 236, 0.28)",
};

export const tabData = [
  {
    name: "Line",
    Icon: LineChartOutlined,
  },
  {
    name: "Baseline",
    Icon: LineChartOutlined,
  },
  {
    name: "Area",
    Icon: AreaChartOutlined,
  },
  {
    name: "CandleStick",
    Icon: BarChartOutlined,
  },
  {
    name: "Bar",
    Icon: BarChartOutlined,
  },
];

export const tabData2 = [
  {
    name: "Area",
    Icon: AreaChartOutlined,
  },
  {
    name: "CandleStick",
    Icon: BarChartOutlined,
  },
];
