import { UTCTimestamp } from "lightweight-charts";
import { useCallback, useEffect, useState } from "react";

const isSameMinute = (date1: Date | null, date2: Date) => {
  if (!date1) {
    return false;
  }
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate() &&
    date1.getHours() === date2.getHours() &&
    date1.getMinutes() === date2.getMinutes()
  );
};

export const useSocketChannel = (
  initialMessage: any,
  chartType: "Candle" | "Area",
  lastTime: Date | null = null,
  enableSocket: boolean = true
) => {
  const [data, setData] = useState<any[]>([]);

  const handleData = useCallback(
    (parsedData: any) => {
      const bid = parseFloat(
        parsedData?.bid && parsedData?.bid !== "" ? parsedData?.bid : "0"
      );
      if (bid === 0) {
        return;
      }
      //GMT time
      const time = Math.floor(new Date().getTime() / 1000);
      const currentData = {
        time: time,
        open: bid,
        high: bid,
        low: bid,
        close: bid,
        value: bid,
      };
      if (chartType === "Candle") {
        //Compare time in same minute, not seconds or milliseconds
        //If the time is in the same minute, calculate high, low, open and close and update candleData
        //If the time is same as the last element in data, update the last element in data, else add a new element to data
        if (!isSameMinute(lastTime, new Date())) {
          setData((d) => {
            if (d?.length > 0) {
              const lastElement = d[d.length - 1];
              if (isSameMinute(new Date(lastElement.time * 1000), new Date())) {
                const updatedData = {
                  ...lastElement,
                  high: Math.max(lastElement.high, bid),
                  low: Math.min(lastElement.low, bid),
                  close: bid,
                  value: bid,
                };
                return [...d.slice(0, d.length - 1), updatedData];
              } else {
                return [...d, currentData];
              }
            }
            return [...d, currentData];
          });
        }
      } else {
        setData((d) => [...d, currentData]);
      }
    },
    [chartType, lastTime]
  );

  useEffect(() => {
    if (!enableSocket) {
      return;
    }
    const socket = new WebSocket("wss://cfd.hisa.co:6001");
    socket.onopen = () => {
      socket.send(JSON.stringify(initialMessage));
    };
    socket.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      handleData(parsedData);
    };
    return () => {
      socket.close();
    };
  }, [initialMessage, chartType, enableSocket, lastTime, handleData]);

  return data?.map((d) => ({
    ...d,
    time: (d?.time + 3 * 60 * 60) as UTCTimestamp,
  }));
};
